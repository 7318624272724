import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Index } from "elasticlunr";
import get from "lodash/get";
import { navigate } from "gatsby";

import Layout from "../components/Layout";
import styled from "styled-components";
import SearchBar from "./../components/SearchBar";
import SearchResultItem from "./../components/SearchResultItem";
import QuestionMark from "./../images/people-site-question-mark-transparent.png";
import * as StyleVars from "./../styles/cssVariables";
import { Title60, Text30 } from "./../components/Typography";

const Container = styled.div`
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1180px) {
    padding: 0 16px;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  @media (${StyleVars.mediaMobile}) {
    padding: 0;
  }
`;

const AppContent = styled.main`
  overflow: hidden;
  padding-bottom: 48px;
`;

const InnerContainer = styled.div`
  padding: 0 70px;

  @media (${StyleVars.mediaMobile}) {
    padding: 0;
  }
`;

const EmptySearchResultContainer = styled.div`
  background-image: url(${QuestionMark});
  background-position: 260px;
  padding: 160px 0 320px 0;
  background-size: contain;
  background-repeat: no-repeat;

  @media (${StyleVars.mediaMobile}) {
    background-position: center top;
    padding: 330px 40px 320px;
  }
`;

const EmptyTextContainer = styled.div`
  width: 50%;

  @media (${StyleVars.mediaMobile}) {
    width: 100%;
  }
`;

const EmptyTitle = styled(Title60)``;

const EmptyText = styled(Text30)``;

const CustomLink = styled.span`
  color: #07b0c5;
  cursor: pointer;
`;

const SearchBarContainer = styled.div`
  .search-container {
    flex-direction: column;
  }

  .btns-container {
    margin-top: 40px;
    justify-content: center;
  }

  .search-btn {
    background-color: #2c465d;
    border-color: #2c465d;

    &:hover {
      background-color: #132433;
      border-color: #132433;
    }
  }

  .surprise-btn {
    border-color: #2c465d;
    color: #2c465d;
    border-width: 2px;

    path {
      fill: #2c465d;
    }

    &:hover {
      background-color: #132433;
      color: #ffffff;

      path {
        fill: #ffffff;
      }
    }
  }

  @media (${StyleVars.mediaMobile}) {
    background-color: #f1f1f1;
    padding: 70px 40px;

    .btns-container {
      justify-content: center;
      margin-top: 0;
    }

    .search-btn {
      display: block;
      margin-right: 0;
    }

    .surprise-btn {
      display: none;
    }
  }
`;

class SearchForm extends React.Component {
  state = {
    results: []
  };

  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      this.query = urlParams.get("query");
    }

    let data = get(this, "props.allMarkdownRemark.edges");
    const randomIndex = Math.floor(Math.random() * (data.length - 1));
    this.surprise = data[randomIndex];
  }

  handleSurpriseClick = () => {
    if (!this.surprise) return;
    navigate(`${this.surprise.node.fields.slug}`);
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const query = urlParams.get("query");
      this.processSearch(query);
    }
  }

  getOrCreateIndex = () =>
    this.index ? this.index : Index.load(this.props.searchIndex);

  handleSearch = async (query, cb) => {
    navigate(`/search/?query=${query}`);
    await this.processSearch(query);
    if (cb) cb();
  };

  processSearch = async (query) => {
    this.query = query;
    this.index = this.getOrCreateIndex();
    const results = this.index
      .search(query, {})
      .map(({ ref }) => this.index.documentStore.getDoc(ref));
    this.setState({
      results
    });
  };

  render() {
    const { results } = this.state;

    return (
      <Layout location={this.props.location}>
        <AppContent>
          <SearchBarContainer>
            <SearchBar
              margin="0 0 50px"
              onSearch={this.handleSearch}
              onSurprise={this.handleSurpriseClick}
              query={this.query}
            />
          </SearchBarContainer>

          <Container>
            <InnerContainer>
              {!results.length && (
                <EmptySearchResultContainer data-cy="empty-container">
                  <EmptyTextContainer>
                    <EmptyTitle>We're stumped.</EmptyTitle>

                    <EmptyText>
                      That particular search term brings up nothing — sorry
                      about that!
                    </EmptyText>
                    <EmptyText>
                      Maybe try looking for{" "}
                      <CustomLink onClick={() => this.handleSearch("software")}>
                        software
                      </CustomLink>
                      , or{" "}
                      <CustomLink onClick={() => this.handleSearch("desk")}>
                        desk
                      </CustomLink>
                      ? Happy reading! :)
                    </EmptyText>
                    <EmptyText>
                      Alternatively, just{" "}
                      <CustomLink as={Link} to="/">
                        browse the profiles
                      </CustomLink>
                      . Happy reading! :)
                    </EmptyText>
                  </EmptyTextContainer>
                </EmptySearchResultContainer>
              )}
              {results.map((item, i) => (
                <SearchResultItem
                  item={item}
                  key={`result_item_${i}_${item.first_name}_${item.last_name}`}
                  query={this.query}
                />
              ))}
            </InnerContainer>
          </Container>
        </AppContent>
      </Layout>
    );
  }
}

class SearchWrapper extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query SearchIndexQuery {
            siteSearchIndex {
              index
            }
            allMarkdownRemark(
              sort: { fields: [frontmatter___date], order: DESC }
            ) {
              edges {
                node {
                  fields {
                    slug
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <SearchForm
            allMarkdownRemark={data.allMarkdownRemark}
            searchIndex={data.siteSearchIndex.index}
            {...this.props}
          />
        )}
      />
    );
  }
}

export default SearchWrapper;
