import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { Text30, Title46CSS, Text24, Text20 } from "./Typography";
import * as StyleVars from "./../styles/cssVariables";

const ItemContainer = styled(Link)`
  display: flex;
  padding: 15px;
  padding: 10px;
  margin-bottom: 50px;

  &:hover {
    background-color: #f1f1f1;
  }

  &:hover .overlay {
    background: #2c465dd9;

    .hover-only {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (${StyleVars.mediaMobile}) {
    display: block;
    padding: 40px;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  overflow: hidden;
  min-width: 300px;
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 48%,
      rgba(0, 0, 0, 0.8) 100%
    );
    z-index: 1;
  }

  @media (${StyleVars.mediaMobile}) {
    min-width: 0;
    min-height: 0;
    max-height: 350px;
    max-width: 100%;
    height: 350px;
  }
`;

const MainImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  background: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  padding: ${(props) => (props.small ? "15px" : "30px")};
  transition: all 0.3s ease;
  z-index: 5;

  &:hover {
    background: #2c465dd9;
  }

  .hover-only {
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover .hover-only {
    opacity: 1;
    visibility: visible;
  }

  @media (${StyleVars.mediaMobile}) {
    .mobile-only {
      padding-bottom: 30px;
    }

    &:hover .hover-only {
      display: none;
    }
  }
`;

const Description = styled.div`
  font-size: 20px;
`;

const MobileJob = styled(Text24)`
  display: none;
  font-family: "Merriweather";
  margin: 5px 0 0;
  width: 80%;

  @media (${StyleVars.mediaMobile}) {
    display: block;
    font-size: 20px;
  }
`;

const Occupation = styled(Text30)`
  color: inherit;
  width: 80%;
  margin-bottom: 20px;
  margin-top: 0;
  position: relative;
  z-index: 3;
  font-family: "Merriweather";
`;

const Name = styled.div`
  color: inherit;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 3;
`;

const FirstName = styled.h3`
  ${Title46CSS}
  color: inherit;
  margin: 0;
  line-height: 1;
  font-weight: 700;
  font-size: 30px;
`;

const LastName = styled.span`
  color: inherit;
  line-height: 1;
  padding-bottom: 3px;
  padding-left: 8px;
  font-size: 20px;
`;

const SearchResultText = styled(Text20)`
  color: #303030;
  padding: 80px 0 50px 70px;
  font-family: "Merriweather";

  @media (${StyleVars.mediaMobile}) {
    padding: 0;
  }
`;

class SearchResultItem extends React.Component {
  state = {
    excerpt: ""
  };

  componentDidMount() {
    const { item, query } = this.props;
    const { content } = item;
    const queryIndex = content.toLowerCase().indexOf(query.toLowerCase());
    let _excerpt = `${content.substr(0, 200)}...`;
    if (queryIndex !== -1) {
      let pre = "";
      if (queryIndex !== 0) pre = `${content.substr(queryIndex - 20, 20)}`;

      const word = content.substr(queryIndex, query.length);
      _excerpt = `...${pre}${word.bold()}${content.substr(
        queryIndex + query.length,
        200
      )}...`;
    }

    this.setState({
      excerpt: _excerpt
    });
  }
  hyper;
  render() {
    const { item, query } = this.props;
    const { excerpt } = this.state;
    let img;
    try {
      img = require(`../../src/pages${item.slug}profile.jpg`).default;
    } catch (error) {
      try {
        img = require(`../../src/pages${item.slug}profile.png`).default;
      } catch (error) {
        img = require(`../../src/pages${item.slug}profile.jpeg`).default;
      }
    }

    return (
      <ItemContainer
        to={`${item.slug}?query=${query}`}
        data-cy="search-result-item"
      >
        <ImgContainer>
          {img && (
            <MainImg src={img} alt={`${item.first_name} ${item.last_name}`} />
          )}
          <Overlay className="overlay">
            {item.job && (
              <Occupation className="hover-only">{item.job}</Occupation>
            )}
            {item.first_name && (
              <Name>
                <FirstName>{item.first_name}</FirstName>
                {item.last_name && (
                  <LastName className="hover-only">{item.last_name}</LastName>
                )}
              </Name>
            )}
            <MobileJob>{item.job}</MobileJob>
            {item.description && <Description>{item.description}</Description>}
          </Overlay>
        </ImgContainer>
        <SearchResultText dangerouslySetInnerHTML={{ __html: excerpt }} />
      </ItemContainer>
    );
  }
}

export default SearchResultItem;
